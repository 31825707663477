@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,900;1,100&display=swap");

/* login page style */
:root {
  --main-bg: rgb(0 0 0 / 88%);
  --input-box-bg: #434344;
  --font-family: "Poppins", sans-serif;
}

#root {
  height: 100vh;
}

.dashboard-body {
  background-image: url("../images/nba_background.jfif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  cursor: pointer;
}

body {
  font-family: var(--font-family) !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6) !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6) !important;
}

.f-1 {
  font-size: 12px;
}

.f-2 {
  font-size: 16px;
}

.f-3 {
  font-size: 18px;
}

.f-4 {
  font-size: 14px;
}

.bold {
  font-weight: bolder;
}

.textColo {
  color: #dbdbdb;
}

body {
  background: #2c2c2c !important;
  font-family: var(--font-family);
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: rgba(29, 195, 249, 1) !important;
  text-decoration: none !important;
}

.btn-cash {
  background-color: #434344 !important;
  border: none !important;
}

a {
  color: #fff !important;
  text-decoration: none !important;
}

.navbar-nav .nav-item {
  text-transform: uppercase;
}

.navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
  font-size: 16px;
  margin: 0px 15px 0px 15px;
  text-decoration: none;
  position: relative;
}

.navbar-nav .nav-link:after {
  bottom: -4px;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  background: #fff !important;
  opacity: 0.6;
  transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
  width: 0;
}

.navbar-nav .nav-link:hover:after {
  width: 100%;
  opacity: 0.9;
  background: #1dbff9 !important;
  color: #1dbff9;
}

.main-bg {
  background: url("../images//sport-football-arena-photography_1409-4807\ \(2\).jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  min-height: 100vh;
}

.main-bg input:focus,
.main-bg button:focus,
.main-bg .form-control:focus {
  border: 1px solid var(--input-box-bg) !important;
  box-shadow: none;
  background-color: var(--input-box-bg) !important;
  color: white !important;
}

.main-bg .form-control {
  border: 1px solid var(--input-box-bg) !important;
  background-color: var(--input-box-bg) !important;
  padding: 0.7rem !important;
  color: white !important;
  border-radius: 10px;
}

.form-check-input:checked {
  background-color: #1676f6 !important;
  border-color: var(--input-box-bg);
}

.card {
  border-radius: 20px !important;
  background-color: var(--main-bg) !important;
  width: 100%;
  height: auto;
  position: relative;
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: -34px;
}

.card-body {
  flex: 1 1 auto;
  padding: 0rem 3rem 1rem 3rem !important;
}

.card-title p {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5%;
}

.form-label {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.img-logo {
  background: linear-gradient(
    90deg,
    rgba(22, 118, 246, 1) 37%,
    rgba(29, 195, 249, 1) 100%
  ) !important;
  padding: 1.5rem 8rem !important;
  border-radius: 15px;
  max-height: 200px;
}

.btn {
  font-size: 18px;
  border-radius: 10px;
}

.main-bg [type="button"]:not(:disabled),
.main-bg [type="reset"]:not(:disabled),
.main-bg [type="submit"]:not(:disabled),
.main-bg button:not(:disabled) {
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(22, 118, 246, 1) 37%,
    rgba(29, 195, 249, 1) 100%
  );
}

.remove-btn .btn .btn-remove {
  background: #000 !important;
}

.terms {
  color: rgba(21, 101, 216, 1);
}

.bottom-text {
  color: rgba(174, 174, 174, 1);
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(134, 146, 166, 1) !important;
  text-align: center;
  white-space: nowrap;
  background-color: var(--input-box-bg) !important;
  border: 1px solid var(--input-box-bg) !important;
  border-radius: 10px;
}

.forget {
  display: flex;
  justify-content: end;
  color: rgba(174, 174, 174, 1);
}

.disabled {
  cursor: not-allowed !important;
}

.link-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.link {
  text-decoration: underline;
  color: rgba(21, 101, 216, 1);
  cursor: pointer;
  text-wrap: nowrap;
}

/* end login page style */

.btn-login {
  color: #fff !important;
  border: 1px solid #dcdcdc;
  padding: 7px 46px;
  border-radius: 5px;
  text-transform: uppercase;
  margin-right: 10px;
}

.btn-register {
  color: #fff !important;
  border: 1px solid
    linear-gradient(
      90deg,
      rgba(22, 118, 246, 1) 37%,
      rgba(29, 195, 249, 1) 100%
    );
  padding: 7px 46px;
  border-radius: 5px;
  text-transform: uppercase;
  background: linear-gradient(
    90deg,
    rgba(22, 118, 246, 1) 37%,
    rgba(29, 195, 249, 1) 100%
  ) !important;
}

.bg-dark {
  background: var(--main-bg) !important;
}

/* main content */
.sport_card {
  background-color: rgba(0, 0, 0, 0.6);
  height: auto;
  border-radius: 10px;
  padding-bottom: 15px;
}

.sport-heading {
  background-color: #000;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sport-content {
  padding: 20px 20px;
  color: #fff;
  font-size: 15px;
}

.table-head {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #fff !important;
  border-bottom-width: 0 !important;
  padding: 0.5rem 0.5rem 0.5rem 1rem !important;
  vertical-align: middle;
}

.table-headdata {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff !important;
  border-bottom-width: 0 !important;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
  vertical-align: middle;
  background-color: #000 !important;
}

.table-head td {
  border-bottom-width: 0 !important;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
}

.table-headdata td {
  border-bottom-width: 0 !important;
}

.bg-table {
  background-color: rgba(37, 37, 37, 0.5);
}

.btn.btn-outline-secondary.btn-sm.btn-width {
  border-color: #1dbff9;
  color: #1dbff9;
  font-weight: 600;
}

.btn.btn-outline-secondary.btn-sm.btn-width:hover {
  background: #1dbff9;
  color: #fff;
}

.btn-odds {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-bg {
  background-color: #000 !important;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.title-section p {
  color: #1676f6;
  font-weight: 600;
}

.at-rate {
  padding: 0 0 0 13px !important;
}

.single-text {
  padding-bottom: 10px;
  padding-top: 10px;
}

.sport-mid-card {
  max-height: 100vh;
  height: 100%;
  background-color: rgb(0 0 0 / 44%) !important;
  border-radius: 10px;
  overflow: scroll;
}

.sport-right-content {
  color: #fff;
  font-size: 15px;
  overflow-y: scroll;
  height: 100%;
}

.fa-minus-circle,
.fa-trash-o {
  color: rgba(209, 39, 39, 1);
  cursor: pointer;
}

.small-text {
  color: rgba(219, 219, 219, 1);
}

.btn-wager {
  background: none !important;
  border: 2px solid #c9c9c9 !important;
  width: 100%;
  text-align: left;
  border-radius: 5px;
  color: #c9c9c9;
}

.bottom-br {
  border-top: 2px solid #c9c9c9;
}

.btn-remove {
  background-color: #000 !important;
  color: #fff;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
}

.btn:hover {
  color: #fff !important;
}

.svg-icon {
  width: 22px;
}

.table > :not(caption) > * > * {
  background-color: #000 !important;
  color: #fff !important;
}

.btn-login {
  color: #fff !important;
  border: 1px solid #dcdcdc !important;
  padding: 7px 46px !important;
  border-radius: 5px !important;
  text-transform: uppercase;
  margin-right: 10px;
}

.btn-register {
  color: #fff !important;
  border: 1px solid rgba(22, 118, 246, 1) !important;
  padding: 7px 46px !important;
  border-radius: 5px !important;
  text-transform: uppercase;
  background: linear-gradient(
    90deg,
    rgba(22, 118, 246, 1) 37%,
    rgba(29, 195, 249, 1) 100%
  ) !important;
}

/* main content end */

/* footer */

.footer {
  background-color: var(--main-bg) !important;
  color: #fff;
  padding: 1rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.footer span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer ul {
  list-style-type: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer li {
  padding: 0 0.5rem;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer li.nav-item {
  font-size: 12px;
}

/* footer end */

/* dashboard stylew end */

.standings {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 180px;
  max-height: 180px;
  -webkit-overflow-scrolling: touch;
}

.flex-standings {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

/* new dashboar css */

.f-1 {
  font-size: 12px;
}

body {
  background: #2c2c2c;
  font-family: var(--font-family);
}

a:hover {
  color: rgba(29, 195, 249, 1);
  /* text-decoration: underline; */
}

.navbar-nav .nav-link {
  color: #fff;
  font-size: 16px;
  margin: 0px 15px 0px 15px;
  text-decoration: none;
  position: relative;
}

.navbar-nav .nav-link:after {
  bottom: -4px;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  background: #fff;
  opacity: 0.6;
  transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
  width: 0;
}

.navbar-nav .nav-link:hover:after {
  width: 100%;
  opacity: 0.9;
  background: #1dbff9;
  color: #1dbff9;
}

.btn-login {
  color: #fff !important;
  border: 1px solid #dcdcdc;
  padding: 7px 46px;
  border-radius: 5px;
  text-transform: uppercase;
  margin-right: 10px;
}

.btn-register {
  color: #fff !important;
  border: 1px solid
    linear-gradient(
      90deg,
      rgba(22, 118, 246, 1) 37%,
      rgba(29, 195, 249, 1) 100%
    );
  padding: 7px 46px;
  border-radius: 5px;
  text-transform: uppercase;
  background: linear-gradient(
    90deg,
    rgba(22, 118, 246, 1) 37%,
    rgba(29, 195, 249, 1) 100%
  );
}

p {
  margin-bottom: 0;
}

.w-40 {
  width: 40px;
}

.grid-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 1.5rem;
}

.avatar {
  width: 40px;
  height: 40px;
  object-fit: fill;
  border-radius: 100%;
}

.btn-width {
  width: 90px;
  height: 50px;
  font-size: 12px;
}

.grid-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sport-right-card {
  background-color: rgba(0, 0, 0, 0.6);
  max-height: 100%;
  height: 100%;
  border-radius: 10px;
  padding-top: 15px;
}

/* Handle */
.table-scroll ::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}

/* Handle on hover */
.table-scroll ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}

.table-scroll-right {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
  height: 40vh;
  padding: 10px;
}

.table-scroll-left {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
}

.standings-section {
  height: 250px;
}

.schedule-section {
  height: 350px;
}

.table-league-left {
  max-height: 100%;
  height: auto;
}

.heading-mid {
  color: #fff;
  padding: 10px;
}

.tableresponsive-head {
  max-height: 250px;
  display: flex;
  justify-content: end;
}

.custom-image {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.head-text {
  grid-gap: 70px;
  font-size: 12px;
  color: #fff;
  padding: 10px 40px 10px 10px;
}

.top-image {
  width: 100%;
  height: 200px;
}

.btn-remove {
  background-color: #000;
  color: #fff;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
}

.btn-loginbet {
  background: linear-gradient(
    90deg,
    rgba(22, 118, 246, 1) 37%,
    rgba(29, 195, 249, 1) 100%
  );
  font-size: 18px;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
}

.svg-icon {
  width: 20px;
  margin-right: 8px;
}

/* dashboard style end */

/* single tam page start */

.media-score-card {
  display: flex;
  padding: 40px;
}

.home-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: white;
  font-size: 1.2rem;
  height: 100%;
}

.score-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  color: white;
  font-size: 1.4rem;
}

.away-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: white;
  font-size: 1.2rem;
}

.sport-footer {
  color: white;
  display: flex;
  justify-content: center;
}

.sport-footer span {
  font-size: 1.2rem;
  margin: 0 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font {
  color: white;
}

.timer {
  font-size: 2rem;
  font-family: sans-serif;
  font-weight: bolder;
}

/* CSS by ankit*/

hr.line {
  color: #fff;
}

.time-section {
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: #2d2d2d;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

span.league-timer {
  color: #1676f6;
  font-weight: 600;
}

input.league-date {
  background: #1dbff9;
  border: 1px solid #1a9df8;
  padding: 3px 10px;
  font-size: 14px;
}

.bet-cash {
  background: #efefef;
  color: #000;
  padding: 2px 5px;
  border-radius: 4px;
}

.betslip-team {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
}

.betslip-team input.form-control {
  font-size: 13px;
  padding: 8px 12px;
}

.remove-btn > p {
  color: #fff;
  font-size: 14px;
  font-weight: 200;
  margin: 5px 15px;
}

.remove-icon {
  font-size: 22px;
  margin-right: 8px;
}

.remove-btn {
  cursor: pointer;
}

.betslip-fixed {
  position: sticky;
  top: 15px;
  background-color: rgb(0 0 0 / 58%) !important;
  padding: 0 3% 6% 3%;
  border-radius: 3%;
}

/* My Bets section start*/

.mybets a.nav-link.active {
  background-color: #1676f6;
  border-color: #1676f6;
}

/* My Bets section end*/
.mybet-wager {
  background-color: #2d2d2d;
  padding: 10px;
}

.user-icon {
  font-size: 32px;
}

.header-amount {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: -10px;
}

.header-bal {
  font-size: 11px;
}

.header-right .btn-outline-secondary:hover {
  background-color: #1ba8f8;
  border-color: #1ba8f8;
}

.single-detail .accordion-button:not(.collapsed),
.accordion-button.collapsed,
.accordion-button:focus {
  background-color: #686868;
  box-shadow: none;
  color: #fff;
}

.single-detail .accordion-item {
  background-color: black;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid #303030;
}

.single-detail th {
  font-weight: 500;
}

.innerteam-logo {
  width: 40px;
  margin-right: 10px;
}

.parent {
  position: relative;
  width: 100%;
}

.vs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: auto;
  z-index: 1;
}

img.team1 {
  position: absolute;
  top: 45%;
  left: 21%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: auto;
  animation: rightFlow 1s ease forwards;
}

@keyframes rightFlow {
  0% {
    transform: translate(-100%);
    opacity: 1;
  }

  100% {
    transform: translate(-50%);
    opacity: 1;
  }
}

img.team2 {
  position: absolute;
  top: 42%;
  right: 21%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: auto;
  rotate: 180deg;
  animation: leftFlow 1s ease forwards;
}

@keyframes leftFlow {
  0% {
    transform: translate(-100%);
    opacity: 1;
  }

  100% {
    transform: translate(-50%);
    opacity: 1;
  }
}

img.teamlogo1 {
  position: absolute;
  top: 15%;
  left: 23%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: auto;
  animation: rightFlow 1s ease forwards;
}

img.teamlogo2 {
  position: absolute;
  top: 14%;
  right: 5%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: auto;
  animation: leftFlow 1s ease forwards;
}

.teamname1 {
  position: absolute;
  top: 51%;
  left: 21%;
  transform: translate(-50%, -50%);
  height: auto;
  animation: rightFlow 1s ease forwards;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
}

.teamname2 {
  position: absolute;
  top: 49%;
  right: 3%;
  transform: translate(-50%, -50%);
  height: auto;
  animation: leftFlow 1s ease forwards;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
}

.parent .q-score {
  right: 10%;
}

.main-menu a {
  text-transform: uppercase;
}

.lock {
  position: relative;
}

.lock i {
  position: absolute;
  font-size: 20px;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #393939a3;
  color: #ddd;
  z-index: 99;
}

.scheduleTiles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.teamLogo img {
  width: 40px;
  object-fit: contain;
  height: 40px;
  margin-right: 8px;
  margin-top: -2px;
  background: #fff;
  border-radius: 50%;
  padding: 1px;
}

.color-white {
  color: white;
  font-weight: 500;
}

.color-blue {
  color: #1676f6;
  font-weight: 700;
}

.standing-hpts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 62px;
}

.standing-pts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.schedule-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.schedule-team {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  width: 335px;
}

.schedule-date {
  flex: 0 0 15%;
}

.coming-soon-bg {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  padding: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.coming-soon-bg .btn-loginbet {
  width: auto;
}

.coming-text {
  font-style: italic;
  font-weight: 600;
}

.betslip-heading {
  flex: 1;
  color: #1676f6;
  font-weight: 600;
  font-size: 24px;
}

.page-bg {
  background-color: rgba(0, 0, 0, 0.6);
  /* height: 100vh; */
  padding: 20px;
  color: #fff;
  border-radius: 10px;
  overflow: auto;
  font-size: 15px;
  font-weight: 300;
}

.page-bg h1 {
  font-size: 32px;
  text-align: center;
}

.page-bg h2,
.page-bg h3,
.page-bg h4 {
  color: #1676f6;
  font-size: 20px;
}

.money-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.betslip-fixed .sport-heading {
  background-color: #2d2d2d;
}

.logout {
  font-size: 18px;
  vertical-align: middle;
}

.overlay {
  width: 75%;
  margin-top: 70px;
}

.about-icon-grp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 20px; */
}

.abt-icons {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.text-center.about-section {
  border: 1px solid #fff;
  padding: 30px;
  border-radius: 10px;
  width: 24%;
}

.faq-stitle {
  font-size: 18px;
  text-align: center;
  color: #4f9ad3;
  font-weight: 500;
  margin-bottom: 40px;
}

.text-area {
  height: 200px;
  padding: 10px;
  border-radius: 8px;
}

.submit-btn {
  background: linear-gradient(
    90deg,
    rgba(22, 118, 246, 1) 37%,
    rgba(29, 195, 249, 1) 100%
  );
  color: #fff;
  width: 100%;
}

.contactform-bg {
  background-color: #212529;
  padding: 30px;
  border-radius: 8px;
}

.title-section {
  display: flex;
  align-items: center;
}

img.lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  transform: translate(-50%, -50%);
}

.score-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 260px;
}

.win-text {
  color: #000;
}

.over-under-txt {
  font-size: 12px;
  font-weight: 500;
}

.btn-grp {
  display: none;
}

.bottom_text {
  text-align: right;
  margin-left: 10px;
}

.btn-grp button#my_bet {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

/* Responsive Code Start */

@media only screen and (max-width: 768px) {
  .schedule-team {
    width: 100%;
  }

  .schedule-team .mb-0 {
    margin: 0 7rem 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .main-bg .img-logo {
    padding: 0.7rem 3rem !important;
  }

  .card-title p {
    font-size: 24px;
  }

  .schedule-area .teamLogo img {
    margin-right: 0;
  }

  .scheduleTiles div {
    flex: 1;
  }

  .scheduleTiles {
    width: 112px;
  }

  .schedule-team .mb-0 {
    margin: 0 1rem 0 0;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .table-bg .LiveGameM {
    /* flex-direction: column; */
  }

  .LiveGame .lock .btn.btn-outline-secondary:hover {
    background-color: red !important;
  }

  .bottom-text {
    font-size: 90%;
  }

  .orderThird {
    order: 3;
  }

  .oderSecond {
    order: 2;
  }

  .orderfirst {
    order: 1;
  }

  .schedule-date {
    flex: 0 0 20%;
  }

  .footer li.nav-item {
    font-size: 11px;
  }
}

@media only screen and (max-width: 576px) {
  .link {
    font-size: 14px;
  }

  .sport-heading {
    flex-wrap: wrap;
  }

  .sport-heading .betslip-heading {
    text-align: center;
  }

  .sport-heading > p {
    width: 100%;
    white-space: nowrap;
    /* text-align: center; */
  }

  .sport-heading a {
    margin: 10px auto;
    width: 100%;
    margin-left: 0 !important;
  }

  .Cstmdirection {
    flex-direction: column-reverse;
  }

  .table-league-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .main-menu {
    gap: 15px !important;
  }

  .bottom_text {
    text-align: left;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .Cstmdirection .orderfirst {
    position: fixed;
    top: 0;
    z-index: 999;
    background: #000;
    display: none;
  }

  .Cstmdirection .orderfirst.show {
    display: block;
  }

  .Cstmdirection .btn-grp {
    display: block;
  }
}

/* Rajay */
.card .btn {
  border: none;
}

.league-section .btn-score {
  position: relative;
}

.lock-icon img {
  position: absolute;
  top: 13%;
  left: 32%;
}

.error-section {
  display: flex;
  justify-content: space-between;
}

.lock-icon img {
  width: 30px;
  height: 30px;
}

.dateTime-section span {
  display: block;
}

.accordian-tabs .tab-content {
  background: #000;
  color: #fff;
  padding: 2%;
}

.accordian-tabs .nav-tabs {
  margin-bottom: 0 !important;
}

.standing-tab p {
  flex: 1;
}

/* 21/02/24 */
@media only screen and (min-width: 1024px) and (max-width: 1380px) {
  .sport-heading {
    flex-direction: column;
  }

  .sport-heading a {
    margin: 10px auto;
    width: 100%;
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1380px) {
  nav.navbar.navbar-expand-lg.navbar-light.bg-dark {
    width: 100%;
  }

  footer.footer {
    width: 100%;
  }
}

.btn-score span {
  padding: 10px;
}

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
  .Cstmdirection .orderThird {
    width: 50%;
  }
}

@media only screen and (min-width: 1025px) {
  .time-section {
    justify-content: center;
    position: relative;
  }

  .league-time {
    position: absolute;
    left: 10px;
  }
}

/* bet highlight */

.highlight {
  background-color: #1dbff9 !important;
  color: white !important;
}

/* updated style starts */

.player_status_wrapper {
  min-width: 80%;
}

.cursor_p {
  cursor: pointer;
}

.team_data_wrapper > div:nth-child(2n) {
  background-color: transparent;
  color: #fff;
}

.team_data_wrapper > div:nth-child(2n + 1) {
  background-color: transparent;
  color: #fff;
}

.team_data_wrapper > div:first-child {
  background-color: #000;
  color: #fff;
}

.team_data_wrapper > div {
  padding: 10px;
}

.team_data_wrapper p {
  margin: 0;
  width: 10%;
}

.team_data_wrapper p:first-child {
  width: 20%;
}

.team_data_wrapper p:first-child:hover {
  text-decoration: underline;
}

.video-container {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-size: 2em;
  margin: auto;
}

.video-container > div {
  width: 100%;
  height: 100%;
}

.animation_wrapper{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.video-container video {
  width: 100%;
  height: 100%;
}

.start-animation,
.round-animation,
.end-animation,
.video-container video {
  position: absolute;
  transition: opacity 0.5s ease-in-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exist {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.player_info_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.player_img_wrapper {
  width: 20%;
}

.player_img_wrapper img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.player_detail_wrapper {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.player_detail_wrapper > div {
  padding: 20px 20px;
}

.player_detail_wrapper > div:nth-of-type(3) > p {
  margin-bottom: 5px;
  font-size: 14px;
}
.player_data_wrapper {
  position: relative;
}

.close_player_modal {
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  cursor: pointer;
  border-radius: 100%;
  color: #fff;
  padding: 0 6px;
}

.close_player_modal:hover {
  color: #000;
  background-color: #fff;
}

.game_bg_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.game_animation_round_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.standing_table_header {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(37, 37, 37, 0.5);
}

.standing_table_header > p {
  margin: 0;
  width: 20%;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
}
.standing_table_header > p:first-child {
  width: 40%;
  text-align: left;
}

.standing_table_body {
  max-height: 250px;
  overflow-y: scroll;
}

.standing_table_body > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.standing_table_body > div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.standing_table_body > div > div:first-child {
  width: 40%;
  padding-left: 16px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.standing_table_body > div > div:last-child {
  width: 60%;
  justify-content: space-around;
}

.standing_table_body > div > div > p {
  margin: 0;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
}

.standing_table_body > div > div > span {
  font-weight: 400;
  font-size: 13px;
}

.standing_table_body img {
  width: 40px;
  object-fit: contain;
  height: 40px;
  margin-right: 8px;
  background: #fff;
  border-radius: 50%;
  padding: 1px;
}

.schedule_table_row > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  flex-wrap: wrap;
}

.schedule_table_row > div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
}

.schedule_table_row > div > div p {
  margin: 0;
}

.schedule_table_row img {
  width: 40px;
  object-fit: contain;
  height: 40px;
  margin-right: 8px;
  background: #fff;
  border-radius: 50%;
  padding: 1px;
}

.schedule_table_row > div > div > div {
  text-align: center;
}

.schedule_table_row > div > div:first-child {
  width: 25%;
  flex-wrap: wrap;
}

.schedule_table_row > div > div:last-child {
  width: 75%;
}

.schedule_table_row > div > div:last-child > div {
  width: 45%;
}

.schedule_table_row > div > div:first-child > span {
  width: 100%;
}

.team_table_wrapper {
  overflow-x: scroll;
}

.team_table_wrapper > div {
  min-width: 738px;
}

.modal-backdrop.show {
  background-color: rgba(255, 255, 255, 0.8);
}

.player_status_wrapper .modal-content {
  background-color: #000;
  color: #fff;
}

.game_animation_round_wrapper {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.left_wrapper,
.right_wrapper {
  position: relative;
  width: 30%;
}

.left_wrapper {
  /* left: 4.4vw; */
  animation: moveRight 1s ease forwards;
}

.right_wrapper {
  /* right: 4.4vw; */
  animation: moveLeft 1s ease forwards;
}

@keyframes moveRight {
  0% {
    left: 0;
  }

  100% {
    left: 4.4vw;
  }
}

@keyframes moveLeft {
  0% {
    right: 0;
  }

  100% {
    right: 4.4vw;
  }
}

.team_logo {
  background-size: contain;
  width: 100%;
  height: 4.5em;
  background-position: center;
  background-repeat: no-repeat;
}

.team_name {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 5em;
  position: relative;
}

.game_animation_round_wrapper p {
  margin: 0;
  font-size: 1.2vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.center_wrapper {
  position: relative;
  width: 18%;
  z-index: 9;
  top: 51px;
}

.center_wrapper > div {
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 4em;
  background-repeat: no-repeat;
}

.all_league_schedule{
  min-width: 470px;
  overflow-x: scroll;
}

.brand_logo{
  max-height: 80px;
}