@media (max-width: 1000px) {
  .text-center.about-section {
    width: 48%;
    margin-bottom: 30px;
  }
}

@media (max-width: 992px) {
  .game_animation_round_wrapper p {
    font-size: 2vw;
  }
  @keyframes moveRight {
    0% {
      left: 0;
    }

    100% {
      left: 8.8vw;
    }
  }

  @keyframes moveLeft {
    0% {
      right: 0;
    }

    100% {
      right: 8.8vw;
    }
  }
}

@media (max-width: 768px) {
  /* .page-bg {
    padding: 20px 10px;
  } */
  .player_info_wrapper {
    justify-content: space-between;
  }
  .player_img_wrapper {
    width: 30%;
  }
  .player_detail_wrapper {
    width: 60%;
    flex-wrap: wrap;
  }
}

@media (max-width: 700px) {
  .league_game_team_row > div:first-child {
    width: 40%;
  }

  .league_game_team_row > div:last-child {
    width: 60%;
  }

  .league_game_team_row > div:first-child > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .league_game_team_row > div:first-child > div:first-child > span {
    width: 100%;
    margin: 0 !important;
  }
  .league_game_header {
    padding: 10px 0;
    width: 60%;
    justify-content: space-around !important;
    grid-gap: unset;
  }
  .league_game_header p {
    width: 90px;
    text-align: center;
  }
  .center_wrapper {
    top: 60px;
  }
}

@media (max-width: 600px) {
  .text-center.about-section {
    width: 100%;
    margin-bottom: 30px;
  }
  .contactform-bg {
    padding: 30px 10px;
  }
  .card-body {
    padding: 0rem 1rem 1rem 1rem !important;
  }
  .btn-width {
    width: 50px;
  }
  .grid-button {
    grid-gap: 5px;
  }
  .league_game_team_row > div:first-child > div:last-child span {
    display: block;
  }
  .game_animation_round_wrapper p {
    font-size: 2.2vw;
  }
}

@media (max-width: 500px) {
  .left_wrapper,
  .right_wrapper {
    width: 35%;
  }
  .game_animation_round_wrapper p {
    font-size: 2.6vw;
  }
  .center_wrapper {
    top: 65px;
  }
  @keyframes moveRight {
    0% {
      left: 0;
    }

    100% {
      left: 5.8vw;
    }
  }

  @keyframes moveLeft {
    0% {
      right: 0;
    }

    100% {
      right: 5.8vw;
    }
  }
}

@media (max-width: 425px) {
  .schedule_table_row > div > div:first-child,
  .schedule_table_row > div > div:last-child {
    width: 100%;
  }
  .schedule_table_row > div > div:last-child > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .schedule_table_row > div > div:first-child {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .schedule_table_row > div > div:first-child > span {
    width: auto;
    margin-right: 10px;
  }
}

@media (max-width: 360px) {
  .left_wrapper,
  .right_wrapper {
    width: 45%;
  }
  .game_animation_round_wrapper p {
    font-size: 3.2vw;
  }
  @keyframes moveRight {
    0% {
      left: 0;
    }

    100% {
      left: 1.8vw;
    }
  }

  @keyframes moveLeft {
    0% {
      right: 0;
    }

    100% {
      right: 1.8vw;
    }
  }
}
